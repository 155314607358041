import './App.css';
import Website from './components/Home';

function App() {
  return (
    <Website/>
  );
}

export default App;
