import React from 'react';
import './home.css';
import { LiaDownloadSolid } from "react-icons/lia";
import { Menu, X, ChevronRight } from 'lucide-react';

const Website = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [clickedFeature, setClickedFeature] = React.useState(null);
  // const [isHovered, setIsHovered] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleFeatureClick = (index) => {
    setClickedFeature(index);
    setTimeout(() => setClickedFeature(null), 300); // Reset after animation
  };

  const features = [
    {
      title: "Chat with PDF",
      description: "Chat with your PDF like you would with your professor"
    },
    {
      title: "Cross-Reference Multiple PDFs",
      description: "No need to manually search through multiple PDFs, we do it for you"
    },
    {
      title: "Peak PDF Research",
      description: "Balance the content of your PDF with latest content from the web"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="fixed w-full bg-white shadow-sm z-50">
        <nav className="max-w-7xl mx-auto px-4 sm:px-8 lg:px-10">
          <div className="flex justify-between items-center h-16">
            {/* Logo Section */}
            <div className="flex items-center space-x-0">
              <span className="text-3xl -mr-3 font-medium" style={{ fontFamily: "'Lugrasimo', cursive" }}>Salo</span>
              <img src="/logo.png" alt="Logo" className="h-12" />
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#home" className="text-gray-700 hover:text-indigo-700">Home</a>
              <a href="#features" className="text-gray-700 hover:text-indigo-700">Features</a>
            </div>

            <button 
            // onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
             class="button-89 bg-blue-50 hover:bg-blue-100 text-gray-600 font-bold py-2 px-2 rounded inline-flex items-center">
              
              {/* {isHovered ? "coming soon" :  */}
              <div className="flex items-center">
              <LiaDownloadSolid size={24} />  
              <a href="https://storage.googleapis.com/saloexecutablestore/Salo%20Setup%201.0.2.exe">
                Download
              </a>
              </div>
              {/* } */}
            </button>

            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>

          {/* Mobile Navigation */}
          {isMenuOpen && (
            <div className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <a href="#home" className="block px-3 py-2 text-gray-600 hover:text-indigo-600">Home</a>
                <a href="#features" className="block px-3 py-2 text-gray-600 hover:text-indigo-600">Features</a>
              </div>
            </div>
          )}
        </nav>
      </header>

      {/* Hero Section */}
      <section id="home" className="pt-20 md:pt-0">
        <div className="animated-bg">
          <div className="content">
        
          <div className="text-center px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
              READ LIKE NEVER BEFORE
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Reading Made Awesome
            </p>
            <a
              href="#features"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Learn More
              <ChevronRight className="ml-2 h-5 w-5" />
            </a>
          </div>
          </div>
          <div class="particles"></div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Our Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
            <button
              // href="#" // Replace with actual link if needed
              className="block text-current no-underline"
              onClick={(e) => {
                // e.preventDefault(); // Prevent default link behavior
                handleFeatureClick(index);
              }}
            >
              <div
                className={`p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 ${
                  clickedFeature === index ? 'vibrate' : ''
                }`}
              >
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            </button>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer id="contact" className="bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p className="text-gray-300">
            © 2024 Salo. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};


export default Website;